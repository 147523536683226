/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

body {
    line-height: 1;
    padding-bottom: 50px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

nav ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

/* change colours to suit your needs */
ins {
    // background-color:#ff9;
    color: #000;
    text-decoration: none;
}

/* change colours to suit your needs */
mark {
    // background-color:#ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

del {
    text-decoration: line-through;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
    display: block;
    height: 1px;
    border: 0;
    // border-top:1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
}

input,
select {
    vertical-align: middle;
}


// // Box sizing resets
// html {
//   box-sizing: border-box;
//   box-sizing: inherit;
// }

// *, *:before, *:after {
//   box-sizing: inherit;
// }

// // Image resets
// img {
//   max-width: 100%;
//   height: auto;
// }

// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }

// // Body Reset
// body {
//   margin: 0;
// }

// p {
//   margin: 0;
// }
.hero-banner-img {
    width: 55%;
}

@media (max-width: 768px) {

    /* Adjust the max-width for your mobile breakpoint */
    .hero-banner-img {
        width: 80%;
    }
}

.custom-button {
    display: inline-block;
    background-color: #549762;
    /* Dark green */
    padding: 1.5rem 1.5rem;
    /* Increased horizontal padding for better proportions */
    border-radius: 50%;
    /* Increased for a more rounded button */
    text-decoration: none;
    color: white;
    /* Ensures the text is visible */
    text-align: center;
    /* Centers the text */
}

.custom-button:hover {
    background-color: #004d00;
    /* Darker green on hover */
}

.custom-button .icon {
    color: white;
    height: 50%;
    width: 90%;
}

@media (max-width: 768px) {
    .custom-button .icon {
        width: 100%;
        /* Full width on mobile screens */
    }
}

.logo-text-white {
    filter: brightness(0) invert(1);
}
/* Default card width */
.responsive-card {
    width: 25rem;
}

/* Mobile-specific styling */
@media (max-width: 768px) {
    .responsive-card {
        width: 20rem;
    }
}


.mobile-card {
    margin-top: 3rem;
}
@media (max-width: 768px) {
    .mobile-card {
        flex-direction: column;
        
    }
}