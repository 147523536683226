.submit-idea-page-content {
  // margin-top: 10rem;

  .idea-impacts {
    margin-top: 15px;
  }

  .error-alert {
    margin-top: 15px;
  }
}
