// Wrapper constants
$wrapper-max-width: 1100px;
$wrapper-padding: 0 16px;

// Header
$outer-header-colour:#F8F9FA;
$inner-header-colour: #F8F9FA;

// Colour Palette
// Main colours
$mlc-main-dark: #549762;
$mlc-main-light: #A0C65F;

// Shades
// $mlc-shade-grey: #f1f2f2;
$mlc-shade-grey: hsl(60, 2%, 96%);
$mlc-shade-light-grey: #F8F9FA; // Also used in header