.loading-spinner-container {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;

  .loading-spinner {
    margin: 0 auto;
    width: 100px;
    height: 100px;
  }
}

.loading-spinner-inline {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.stepper div div div div{
  a {
    line-height: 1.8 !important;
     }
     span {
    line-height: 1.1 !important;
     }
}

.custom-dropdown-button .dropdown-toggle {
  font-size: 1.5rem;
  line-height: '1'; 
  height: 'auto';
}

.cursor-pointer {
  cursor: pointer;
}