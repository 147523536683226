.wrapper {
  padding: $wrapper-padding;
  max-width: $wrapper-max-width;

  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 5em; // Give margin space for display ads banner on mobile view

  background-color: #fff;
  // box-shadow: 2px 2px 5px #C4C4C4;
  position: relative;
  min-height: 95vh;
}